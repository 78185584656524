/*!
 *  - news.js
 * @generated Mon Apr 22 2019 15:20:36 GMT+0900 (東京 (標準時))
 */
'use strict';

/*
 * jQuery shop v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function ($, DUNLOP) {
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';
	var $map = location.pathname;

	// 最新情報、ニュースリリース共通
	if ($('.updates-idx, .newsrelease-idx').length) {
		var latestY = $('.rt_latest_year').text(),
		    earliestY = $('.rt_earliest_year').text(),
		    yDif = latestY - earliestY;
		var map = $map.split('/'),
		    mapY = map[map.length - 2],
		    cat = map[map.length - 3];

		// 年号プルダウン
		for (var i = 0; i <= yDif; i++) {
			var yearLits = +earliestY + i;
			$('select*[name=select-year]').prepend('<option value="../' + yearLits + '/">' + yearLits + '</option>');
		}
		$('select*[name=select-year] option').each(function () {
			if ($(this).val().indexOf(mapY) !== -1) {
				$(this).attr('selected', 'selected');
			}
		});
		$('select*[name=select-year]').on('change', function () {
			location.href = $(this).val();
		});

		// 絞り込みがある場合の最新情報 カレント
		if ($('.filter-toggle').length) {
			$('.rt_cf_n_tags_href').each(function () {
				if ($map.indexOf($(this).attr('href')) !== -1) {
					$('.updates-idx-filter-item').removeClass(a);
					$(this).parent('.updates-idx-filter-item').addClass(a);
				}
			});
			if ($('.updates-idx-filter-item.is-active').length === 0) {
				$('.updates-idx-filter-item:first-child').addClass(a);
			}
		}
	}

	// 最新情報(updates)
	if ($('#pagetop').hasClass('updates-idx')) {
		$('.filter-toggle').on('click', function () {
			$(this).toggleClass(a);
			$('.filter-toggleBlock').stop().slideToggle(slideSpeed);
		});
	}

	// ニュースリリース
	if ($('.newsrelease').length) {
		$('.newsCat-btn*[data-cat="all"]').addClass(a);
		$('.newsCat-btn').each(function () {
			var cat = $(this).attr('data-cat');
			if ($map.indexOf(cat) !== -1) {
				$(this).addClass(a);
				$('.newsCat-btn*[data-cat="all"]').removeClass(a);
			}
		});
	}

	// 最新情報の「開催中」「終了しました」タグの表示・非表示
	// [ポータル][ゴルフ]トップ
	if ($('.rt_cf_n_type_is_5').length) {
		var targetOpen, targetClose;

		var date = new Date(),
		    y = date.getFullYear(),
		    m = date.getMonth() + 1,
		    d = date.getDate();
		if (String(m).length === 1) {
			m = '0' + m;
		}
		if (String(d).length === 1) {
			d = '0' + d;
		}
		var today = y + m + d;

		if ($('.index').length) {
			targetOpen = $('.mod-boxLink02-status.open');
			targetClose = $('.mod-boxLink02-status.close');
		} else if ($('.updates-idx').length) {
			targetOpen = $('.mod-newsList-status.open');
			targetClose = $('.mod-newsList-status.close');
		}

		$('.rt_cf_n_type_is_5').each(function () {
			var startDate = $(this).find('.rt_cf_n_start_date').text(),
			    endDate = $(this).find('.rt_cf_n_end_date').text();

			if ($(this).find('.rt_cf_n_start_date').length !== 0) {
				if (today >= startDate) {
					if (today > endDate) {
						$(this).find(targetClose).addClass('di_iblock');
					} else {
						$(this).find(targetOpen).addClass('di_iblock');
					}
				}
			}
		});
	}
})(window.jQuery, window.DUNLOP);